import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import eliminarRule from "../../services/Reglas/eliminarRule"
import alertify from "alertifyjs"
import nameApplications from "../variables/application"

import IpsRuleMasivasForm from "../formularios/ipsRuleMasiva"
import IpsForm from "../formularios/ipsRules"
import { TableConfig } from "./actions"
import ModalForm from "../../components/Modal/modal"
import Loading from "../../components/Loading/Loading"

import Redirect from "../../hooks/redirect"
import styles from '../../style/body.module.css'
import NotFound from "../../components/404/404"
import { confirmDialog } from "../../components/Alert/Alert"
import header from "./header"
import { useAppContext } from "./RulesContext"
import useGetRules from "../../hooks/Rules/useGetRules"
import { ruleButtons } from "./buttonsTable"
import MuiTable from "../../components/Table/MuiTable"

export default function IpsValidas() {

    const { getRules } = useGetRules()

    const [title] = useState(localStorage.getItem("application"))
    const [data, setData] = useState(null)
    const [datos, setDatos] = useState(null)
    const [loading, setLoading] = useState(false)
    const [estado, setEstado] = useState(false)
    const [open, setOpen] = useState(false)
    const [modalOption, setModalOption] = useState("ip")
    const [searchValue, setSearchValue] = useState(null)

    const [error, setError] = useState(null)

    const [statusTable, setStatusTable] = useState({
        filter: null,
        column: null
    })

    const store = useAppContext()

    const handleOpen = (modalOpt) => {
        setModalOption(modalOpt)
        setDatos(null)
        setOpen(true)
    }

    const handleClose = () => setOpen(false);
    const handleLoading = ((valor) => { setLoading(valor) })
    const resetData = () => { setData(null) }

    const eliminar = (id) => {

        let saveStatus = Object.assign({}, store.status)
        setStatusTable({
            ...statusTable,
            filter: saveStatus,
            column: store.columnsState
        })

        confirmDialog('Eliminar', 'Este proceso no es reversible ¿Desea continuar?')
            .then((result) => {
                if (result.value) {
                    handleLoading(true)
                    resetData()
                    deleteRule(id, setLoading, setEstado, estado, handleLoading)
                }
            })
    }

    const editar = (datos) => {
        setModalOption("ip")
        setOpen(true)
        setDatos(datos)
    }

    const headerButtons = ruleButtons(handleOpen)

    const handleSearch = (value) => {
        setSearchValue(value);
    }

    const handleChangeTable = (action, tableState) => {
        const columns = {}

        tableState.columns.forEach((element, index) => {
            columns[index.toString()] = element.display
        })

        store.columnsState = columns

        store.filterData = tableState.filterData

        if (tableState.filterList.length > 0) {
            tableState.filterList.forEach((v, index) => {
                tableState.filterList[index] = v.filter(elemento => tableState.filterData[index].includes(elemento))
            })

            if (JSON.stringify(store.status) !== JSON.stringify(tableState.filterList)) {
                store.status = tableState.filterList

                setStatusTable({
                    ...statusTable,
                    filter: store.status,
                    column: store.columnsState
                })
            }
        }
    }

    const atributos = header
    let encabezadoTabla = TableConfig(atributos, statusTable, editar, eliminar)

    useEffect(() => {
        setTimeout(() => {
            getRules().then(result => {
                if (result === null) {
                    setError("Hemos tenido problemas para obtener la data")
                }

                setData(result)
            })
        }, 100)
    }, [estado])

    const options = {
        expandableRowsOnClick: true,
        responsive: "standard",
        searchPlaceholder: "Buscar",
        tableBodyHeight: "estandar",
        tableBodyMaxHeight: "estandar",
        filterType: "dropdown",
        download: false,
        filter: true,
        print: false,
        selectableRows: "none",
        onSearchChange: handleSearch,
        searchText: searchValue,
        pagination: data !== null && data.length > 0 ? true : false,
        onTableChange: handleChangeTable,
        customToolbar: headerButtons,
    }

    if (error) {
        return (<NotFound />)
    }

    else if (loading === true || data === null) {
        return (<Loading />)
    }

    return (
        <div className={styles.centre}>
            <h1>{nameApplications[title]}</h1>
            <Grid container spacing={1} style={{ marginLeft: 5, marginRight: 5 }}>
                <ModalForm open={open} handleClose={handleClose} title="Editar regla">
                    {
                        modalOption === "ip"
                            ? <IpsForm
                                handleClose={handleClose}
                                onChange={() => { setEstado(!estado) }}
                                parametros={datos ? datos : null}
                                onLoading={handleLoading}
                                setData={resetData}
                            />
                            : <IpsRuleMasivasForm
                                handleClose={handleClose}
                                onChange={() => { setEstado(!estado) }}
                                onLoading={handleLoading}
                                resetData={resetData}
                            />
                    }
                </ModalForm>

                <MuiTable
                    title="Ips validas"
                    header={encabezadoTabla}
                    data={data === null || loading === true ? [] : data}
                    options={options}
                />
                <Redirect></Redirect>
            </Grid>
        </div>
    )
}

function deleteRule(id, setLoading, setEstado, estado, handleLoading) {

    eliminarRule(id).then((response) => {
        setLoading(true)

        if (response.message === "Se eliminó la regla correctamente del grupo de seguridad") {
            setEstado(!estado)
            handleLoading(false)
            alertify.success("Elemento eliminado correctamente.")
        } else {
            alertify.error(response.message)
        }
    }).catch(error => {
        alertify.error(error)
    })
}