import React from 'react';
import ModalDialog from '@mui/joy/ModalDialog';
import { Modal, Stack, Typography } from '@mui/joy';

const ModalForm = ({ children, open, handleClose, title = "Editar regla" }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{ alignItems: "center", justifyContent: "center" }}
        >
            <ModalDialog
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ maxWidth: 500, minWidth: 400 }}
                style={{ backgroundColor: "white", borderColor: "#CDCDCD" }}
            >

                <Typography id="basic-modal-dialog-title" component="h2">
                    {title}
                </Typography>

                <Stack spacing={2}>
                    {children}
                </Stack>
            </ModalDialog>
        </Modal>
    )
};

export default ModalForm