import React from 'react';
import MUIDataTable from "mui-datatables"

import EsLabels from './EsLabels'
import { theme } from './TableTheme'
import { ThemeProvider } from '@mui/material/styles';

const MuiTable = ({ title = "Generic Table", header, data, options }) => {

    if (options !== undefined && options !== null) {
        options.textLabels = EsLabels
        options.responsive = 'vertical'
    }

    return (
        <ThemeProvider theme={theme} >
            <MUIDataTable
                title={title}
                data={data}
                columns={header}
                options={options}
            />
        </ThemeProvider >
    )
}

export default MuiTable