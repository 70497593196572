import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import EsLabels from "./EsLabels";

const BasicTable = ({ columns = ["estado", "dias"], data, order = ["", "desc"], Footer = null, customSort = null, total = 0 }) => {

    const options = {
        expandableRowsOnClick: false,
        responsive: "standard",
        searchPlaceholder: "Buscar",
        tableBodyHeight: "estandar",
        tableBodyMaxHeight: "estandar",
        filterType: "dropdown",
        search: false,
        download: false,
        filter: false,
        print: false,
        pagination: false,
        selectableRows: "none",
        viewColumns: false,
        textLabels: EsLabels,
        sortOrder: {
            name: order[0],
            direction: order[1]
        },
        customTableBodyFooterRender: Footer === null ? null : () => {
            return <Footer data={data} total={total} />
        },
        customSort: customSort ? customSort : null,
    }

    const theme = createTheme({
        components: {
            MuiTable: {
                styleOverrides: {
                    root: {
                        marginBottom: "0px",
                    }
                }
            },
            MUIDataTable: {
                styleOverrides: {
                    paper: {
                        borderRadius: '8px',
                    },
                    tableRoot: {
                        fontFamily: 'Arial, sans-serif',
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    footer: {
                        borderRadius: '8px',
                        margin: "0 1em 0 auto",
                        fontFamily: 'Arial, sans-serif',
                        fontSize: 15,
                        fontWeight: 'bold'
                    }
                }
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Arial, sans-serif',
                        color: "#485261",
                        fontSize: 15,
                    }
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        width: '100%'
                    },
                },
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        marginTop: "0px",
                        marginBottom: "0px",
                        padding: "3px .5em 3px .5em",
                        fontFamily: 'Arial, sans-serif',
                        color: "#485261",
                        fontSize: 15,
                        textAlign: "right"
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        marginTop: "0px",
                        padding: "3px 1em 3px 1em"
                    }
                }
            }
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </ThemeProvider>
    )
}

export default BasicTable