import React from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { green, red } from '@mui/material/colors';

import { Power, PowerOffRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';

export const FullCard = ({ children }) => {
    return (
        <Card sx={{ maxWidth: 345 }}>
            {children}
        </Card>
    )
}

const CardFullHeader = (props) => {
    return (
        <CardHeader
            title={props.title}
            subheader={props.instance}
            style={{ backgroundColor: "#fb9c30", color: "white" }}
        />
    )
}

const CardIconCheck = () => {

    let style = { color: green[500], marginRight: 5, marginLeft: 5, marginBottom: 3 }

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Power
                    style={style}
                    fontSize="large">
                </Power>
            </Grid>
            <Grid item xs={4}>
                <p style={{ top: 50 }}>Activa</p>
            </Grid>
        </Grid>
    )
}

const CardIconUnpublished = () => {

    let style = { color: red[500], marginRight: 5, marginLeft: 5, marginBottom: 3 }
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <PowerOffRounded
                    style={style}
                    fontSize="large">
                </PowerOffRounded>
            </Grid>
            <Grid item xs={4}>
                <p style={{ top: 50 }}>Inactiva</p>
            </Grid>
        </Grid>
    )
}

const CardState = (props) => {
    return (
        props.estado ?
            <CardIconCheck></CardIconCheck>
            : <CardIconUnpublished></CardIconUnpublished>
    )
}

FullCard.Header = CardFullHeader
FullCard.Estado = CardState

export default FullCard