import { Stack } from '@mui/joy';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import BasicTable from '../Table/BasicTable';
import defaultConfig from './Config/config';

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const categorias = (data) => {
    const categorias = []

    data.forEach(element => {
        categorias.push(element.name)
    });

    return categorias
}

const getOptions = (data, title, type) => {
    const options = {
        chart: {
            type,
            height: "60%",
            width: 900
        },
        title: {
            text: title
        },
        yAxis: {
            title: {
                text: null,
            },
        },
        xAxis: {
            categories: categorias(data)
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: type === "column" ? '{point.y}' : '<span>{point.name}</span>: {point.y:,.2f}%'
                }
            }
        },
        tooltip: {
            valueSuffix: '',
            pointFormat: type === "column" ? '<span>Dias</span>: <b>{point.y:.0f}</b>' : '<b>{point.y:.2f}%</b>'

        },
        series: [
            {
                name: "Dias",
                data: data,
                colorByPoint: true,
                showInLegend: type === "pie" ? true : false
            }
        ],
    }

    return Object.assign({}, options, defaultConfig)
}

const ChartBar = ({ type = "column", data, columnas = [], title = "grafico" }) => {

    const final = []

    data.forEach(element => {

        const valor = []

        for (const key in element) {
            valor.push(element[key])
        }

        if (element !== undefined) {
            final.push(valor)
        }
    })

    return (
        <Stack direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}>

            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(data, title, type)}
            />

            <BasicTable
                columns={columnas}
                data={final}
                order={['orden', 'asc']} />
        </Stack>
    )
}

export default ChartBar