import { createTheme } from "@mui/material";

export const theme = createTheme({
    overrides: {
        MUIDataTableHeadCell: {
            toolButton: {
                align: "center",
                width: "100%",
                margin: "0 1em 0 auto"
            }
        },
        MUIDataTableBodyCell: {
            root: {
                marginTop: "0px",
                marginBottom: "0px",
            }
        },
        MuiTable: {
            root: {
                marginBottom: "0px",
            }
        },
        MuiTablePagination: {
            root: {
                marginTop: "0px"
            }
        }
    },
})