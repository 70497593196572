import React from "react"
import { Field } from "formik"
import '../style/styles.css'

function Input(props) {
    const { label, name, ...rest } = props

    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <Field
                className="campos"
                style={{ backgroundColor: "white" }}
                id={name}
                name={name}
                {...rest}
            />
            <br />
        </div>
    )
}

export default Input