import { formatNumberSeparateMiles } from "../../../utils/Number"

export const decimalFormat = value => {
    const roundedValue = value.toFixed(2)
    const integerPart = parseInt(roundedValue)
    const decimalPart = parseInt((roundedValue - integerPart) * 100)
    const displayValue = decimalPart !== 0 ? roundedValue : integerPart

    const output = formatNumberSeparateMiles(displayValue)

    return <span>{output}</span>
}

export const decimalFormatInteger = value => {
    const output = formatNumberSeparateMiles(value)
    return <span>{output}</span>
}

export const nameCustom = data => {
    return <div align="left">{data}</div>
}