const defaultConfig = {
    lang: {
        downloadPDF: "Descargar PDF",
        viewFullscreen: "Ver toda la pantalla",
        downloadSVG: "Descargar SVG",
        downloadPNG: "Descargar PNG",
        thousandsSep: "",
        decimalPoint: '.'
    },
    exporting: {
        accessibility: {
            enabled: true,
        },
        enabled: true,
        buttons: {
            enabled: true,
            contextButton: {
                menuItems: ["downloadPNG", "downloadSVG"],
                enabled: true,
            },
        },
        filename: "Indicador",
        sourceWidth: 1280,
        sourceHeight: 700,
        scale: 1,
        url: "https://sgi.almeraim.com/sgi/lib/php/exportar_img.php",
        urlx: "/sgi/lib/php/exportar_img.php",
    },
    navigation: {
        buttonOptions: {
            align: 'right',
            verticalAlign: 'top',
            y: 0
        }
    },
    credits: {
        enabled: false
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 900
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                    labels: {
                        align: 'left',
                        x: 0,
                        y: -5
                    },
                    title: {
                        text: null
                    }
                },
                subtitle: {
                    text: null
                },
                credits: {
                    enabled: false
                }
            }
        }]
    }
}

export default defaultConfig