import Textarea from "./Textarea"
import Input from './Input'
import RFT from "./RFT"
import Checkbox from "./Checkbox"
import Select from "./Select"

function FormikControl(props) {
    const { control, ...rest } = props

    switch (control) {
        case 'input': return <Input {...rest} />
        case 'textarea': return <Textarea  {...rest} />
        case 'RTE': return <RFT {...rest} />
        case 'checkbox': return <Checkbox {...rest} />
        case 'select': return <Select {...rest} />
        case 'radio':
        case 'date':
        default: return null
    }
}

export default FormikControl