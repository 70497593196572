import React from 'react'
import Redirect from '../hooks/redirect';
import Validar from './validar';

const Main = () => {
    return (
        <div style={{marginTop:20,marginRight:20,marginLeft:20}}>
            <Validar></Validar>
            <Redirect instance={localStorage.getItem("instance")+"pruebas"}></Redirect>
        </div>
    )
}

export default Main