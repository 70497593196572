import { CardOverflow, Stack } from "@mui/joy"
import { Card, Typography } from "@mui/material"

import { useLottie } from "lottie-react"
import animationData from './empty-box.json'

const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    style: {
        height: "60%",
        width: "60%",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

const NotData = () => {

    const { View } = useLottie(options)

    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: "2em", textAlign: "center" }}
        >
            <Card variant="outlined" sx={{ width: '50em', height: "auto", justifyContent: "center" }}>
                <CardOverflow>
                    <Typography
                        level="body6"
                        sx={{
                            color: "#868686",
                            fontSize: 'lg',
                            mt: 2,
                            mb: 2,
                            fontFamily: "Arial"
                        }}>
                        En este momento no tenemos información para este periodo de tiempo.
                    </Typography>
                </CardOverflow>
            </Card>
            {View}
        </Stack>

    </div>
}

export default NotData