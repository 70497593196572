import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Chip } from "@mui/joy"

const header = [
    {
        name: "id",
        display: false
    },
    {
        name: "nombre",
        style: {
            textAlign: "justify",
        }
    },
    {
        name: "codigo",
        label: "código"
    },
    {
        name: "activo",
        label: "estado",
        bodyRender: (data, dataIndex, rowIndex) => {

            const estado = data === "Activa" ? true : false

            return (
                <Chip
                    variant="outlined"
                    color="neutral"
                    size="md"
                    style={estado
                        ? { backgroundColor: "#00c853", color: "#ffffff", border: "none" }
                        : { backgroundColor: "#f44336", color: "#ffffff", border: "none" }
                    }
                    endDecorator={
                        estado ?
                            <CheckCircleOutline fontSize="small" />
                            : <ErrorOutline fontSize="small" />
                    }
                >
                    {data}
                </Chip >
            );
        }
    }
]

export default header