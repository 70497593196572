import { CardOverflow, Stack } from "@mui/joy";
import { Card, Typography } from "@mui/material";
import { useLottie } from "lottie-react";
import animationData from './programming-computer.json'

const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    style: {
        height: "100%",
        width: "100%",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

const Description = () => {

    const { View } = useLottie(options)

    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        border: "solid 2px",
        borderColor: "#CDCDCD",
        boxShadow: "5px 5px 5px #DCDCDC"

    }}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
            style={{ marginTop: "2em", textAlign: "center" }}
        >
            <Card variant="outlined" sx={{
                width: '24em',
                height: "auto"
            }}>
                <CardOverflow>
                    <Typography
                        level="body6"
                        sx={{
                            color: "#868686",
                            fontSize: '20px',
                            mt: 2,
                            mb: 2,
                            fontFamily: "Arial"
                        }}>
                        Selecciona un atributo, por favor.
                    </Typography>
                </CardOverflow>
            </Card>
            {View}
        </Stack>
    </div>
}

export default Description