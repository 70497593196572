import { useEffect, useRef } from "react"
import { Alert, Grid } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { ipsMasivasSchema } from "./schemas/schemas"
import adicionarRules from "../../services/Reglas/adicionarRule"
import alertify from "alertifyjs"
import { Stack } from "@mui/joy"
import FormikControl from "./componentes/FormikControl"

import ButtonForm from "../../components/Button/ButtonForm"

const IpsRuleMasivasForm = ({ onChange, onLoading, handleClose, resetData }) => {

    const firstNameRef = useRef(null)
    const initialValues = {
        name: '',
        ip: '',
        id: ''
    }

    const onSubmit = (fields, { setStatus, setSubmitting }) => {
        fields.ip = fields.ip.trim()
        fields.name = fields.name.trim()
        resetData()

        let inicialString = fields.ip.replace(/\n/gi, ",")
        inicialString = inicialString.replace(/\s/gi, ",")
        inicialString = inicialString.replace(";", ",")
        const valores = inicialString.split(",")

        const filtrado = valores.filter(valor => valor.trim() !== '' && valor.trim() !== undefined)

        fields.ip = filtrado

        createRule(fields)
    }

    const correcto = (mensaje = "La regla ha sido creada correctamente") => {
        onChange()
        onLoading(false)
        handleClose()
        alertify.success(mensaje)
    }

    const error = (mensaje = "Hemos tenido un problema para crear la regla.") => {
        onChange()
        onLoading(false)
        handleClose()
        alertify.error(mensaje);
    }

    const createRule = (values) => {
        onLoading(true)

        adicionarRules(values).then((response) => {
            if (response.message !== undefined && response.message === "Se agregaron las reglas de forma masiva correctamente en el grupo de seguridad") {
                correcto(response.message)
            } else {
                error(response.message)
            }
        }).catch((err => {
            error()
        }))
    }

    useEffect(() => {
        if (firstNameRef.current) {
            firstNameRef.current.focus();
        }
    }, [firstNameRef])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={ipsMasivasSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                return (
                    <Form>

                        <Field name="ip" type="hidden" />

                        <FormikControl type="text"
                            innerRef={firstNameRef}
                            control="input"
                            label="Nombre"
                            name="name"
                            placeholder="Ingrese un nombre"
                            autoComplete="off" />
                        <br />

                        <FormikControl control="textarea" label="IP" name="ip" onKeyPress={e => {
                            if (e.key === 'Enter' || e.charCode === 32 || e.key === "," || e.key === ";") {
                                let valor = values.ip.trim()
                                valor = valor.replace(/\n\n/gi, "\n").trim()
                                valor = valor.replace(/(\s{2,})/gi, "\n").trim()
                                valor = valor.replace(/(,{2,})/gi, ",").trim()
                                valor = valor.replace(/(;{2,})/gi, ";").trim()
                                valor = valor.replace(/((,|;|\s){2,})/gi, ",").trim()

                                setFieldValue("ip", valor, false)
                            }
                        }} />

                        <Grid item container spacing={1} justify="center" style={{ marginTop: "2px" }}>
                            <Grid item xs={12} sm={12} md={12}>
                                {(errors.name || errors.ip) && (touched.name || touched.ip) && (
                                    <Alert severity="error" style={{color: '#5F2120', backgroundColor: '#FDEDED'}} >{errors.name || errors.ip}</Alert>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                    sx={{ width: '100%' }}>
                                    <ButtonForm type="submit" label="Aceptar" />
                                    <ButtonForm type="button" label="Cancelar" onClick={handleClose} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        </Formik >
    )
}

export default IpsRuleMasivasForm