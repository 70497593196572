import Cookies from 'js-cookie'

const getMensajes = async () => {

    const endpoint = "http://10.0.0.158:8000/messages"
    const instance = localStorage.getItem("instance")
    const me = localStorage.getItem("api") + "me"

    try {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
                "Permission": me
            },
        }

        const response = await fetch(endpoint, config)
        const json = await response.json()
        return json
    } catch (error) {
        throw Error('Se ha producido un error durante el fetch')
    }
}

export default getMensajes