export function setStateTotal(datos, setDataPieChart) {
    const state_total = []

    if (datos.data.state_total !== null && datos.data.state_total !== undefined) {
        datos.data.state_total.forEach(element => {
            const info = { name: element.state, y: element.percent, total: element.total, order: element.order }
            state_total.push(info)
        })

        setDataPieChart(state_total)
    }
}

export function setStateDay(datos, setDataChart) {
    const valores = []

    if (datos.data.state_day !== null && datos.data.state_day !== undefined) {
        datos.data.state_day.forEach(element => {
            const info = { name: element.state, y: element.days * 1 }
            valores.push(info)
        })

        setDataChart(valores)
    }
}

export function setFetchValues(selected, campos, estados) {
    
        selected.forEach(valor => {
            if (valor.items.length > 0) {
                if (valor.field_id !== "states") {
                    const fields = []

                    valor.items.forEach(element => {
                        fields.push(element.value * 1)
                    })

                    const field = { field_id: valor.field_id * 1, items: fields }
                    campos.push(field)

                } else if (valor.field_id === "states") {
                    valor.items.forEach(element => {
                        estados.push(element.value * 1)
                    })
                }
            }
        })
}

export function getFieldsSelected(selected) {
    const campos = []

    selected.forEach(valor => {
        if (valor.items.length > 0) {
            if (valor.field_id !== "states") {
                const fields = []

                valor.items.forEach(element => {
                    fields.push(element.value * 1)
                })

                const field = { field_id: valor.field_id * 1, items: fields }
                campos.push(field)
            }
        }
    })

    return campos
}