import { CircularProgress } from "@mui/material";

const Loading = () => {
    return (
        <CircularProgress color="inherit"
            style={{
                position: 'absolute', left: '50%', top: '50%',
            }} />
    )
};

export default Loading