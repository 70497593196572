import { useEffect, useRef } from "react";
import { Alert, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik"
import { ipsSchema } from "./schemas/schemas"
import adicionarRules from "../../services/Reglas/adicionarRule"
import editarRule from "../../services/Reglas/editarRule"
import alertify from "alertifyjs"
import { Stack } from "@mui/joy"

import ButtonForm from "../../components/Button/ButtonForm"
import FormikControl from "./componentes/FormikControl"

const IpsForm = ({ onChange, onLoading, handleClose, parametros, setData }) => {

    const firstNameRef = useRef(null);
    const isAddMode = !parametros;
    const initialValues = {
        name: '',
        ip: localStorage.getItem("ip"),
        id: ''
    }

    const onSubmit = (fields, { setStatus, setSubmitting }) => {
        onLoading(true)
        setData()

        fields.ip = fields.ip.trim()
        fields.name = fields.name.trim()

        if (parametros == null) {
            createRule(fields)
        } else {
            updateRule(fields)
        }
    }

    const correcto = (mensaje = "La regla ha sido creada correctamente") => {
        handleClose()
        onChange()
        onLoading(false)
        alertify.success(mensaje)
    }

    const error = (mensaje = "Hemos tenido un problema para crear la regla.") => {
        handleClose()
        onChange()
        onLoading(false)
        alertify.error(mensaje)
    }

    const createRule = (values) => {
        adicionarRules(values).then((response) => {
            if (response.message !== undefined && response.statusCode === 200) {
                correcto(response.message)
            } else {
                error(response.message)
            }
        }).catch((err => {
            error()
        }))
    }

    const updateRule = (values) => {
        editarRule(values).then((response) => {
            if (response.message !== undefined && response.message === "Se modificó la regla correctamente en el grupo de seguridad") {
                correcto("La regla ha sido actualizada correctamente.")
            } else {
                error('Hemos tenido un problema para actualizar la regla.')
            }
        }).catch((err => {
            error('Hemos tenido un problema para actualizar la regla.')
        }))
    }

    useEffect(() => {
        if (!isAddMode) {
            const fields = ['ip', 'name', "id"];
            fields.forEach(field => initialValues[field] = parametros[field]);
        }
    }, [isAddMode, parametros])

    useEffect(() => {
        if (firstNameRef.current) {
            firstNameRef.current.focus();
        }
    }, [firstNameRef])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={ipsSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => {
                return (
                    <Form>

                        <Field name="ip" type="hidden" />

                        <FormikControl type="text"
                            innerRef={firstNameRef}
                            control="input"
                            label="Nombre"
                            name="name"
                            placeholder="Ingrese un nombre"
                            autoComplete="off" />

                        <br />

                        <FormikControl type="text"
                            control="input"
                            label="Ip"
                            name="ip"
                            placeholder="Ingrese una ip"
                            autoComplete="off" />

                        <Grid item container spacing={1} justify="center" style={{ marginTop: "2px" }}>
                            <Grid item xs={12} sm={12} md={12}>
                                {(errors.name || errors.ip) && (touched.name || touched.ip) && (
                                    <Alert severity="error" style={{ color: '#5F2120', backgroundColor: '#FDEDED' }}>{errors.name || errors.ip}</Alert>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <Stack direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                    sx={{ width: '100%' }}>
                                    <ButtonForm type="submit" label="Aceptar" />
                                    <ButtonForm type="button" label="Cancelar" onClick={handleClose} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        </Formik >
    )
}

export default IpsForm