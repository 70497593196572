import getMensajes from "../../services/Mensajes/getMensajes"

const useGetMessages = () => {
    const getMessages = async () => {
        try {
            const response = await getMensajes()

            if (response.length > 0) {
                const datosTabla = []
                let datos = []

                datos = response

                datos.forEach(element => {
                    const dato = [
                        element.id,
                        element.nombre,
                        element.codigo,
                        element.activo === "T" ? "Activa" : "Inactiva"]
                    datosTabla.push(dato)
                })

                return datosTabla

            } else {
                return []
            }

        } catch (error) {
            return []
        }
    }

    return { getMessages }
}

export default useGetMessages