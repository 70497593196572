
export function formatNumberSeparateMiles(number) {
    const valorFormater = Number(number).toLocaleString('es-MX',
        {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })

    const output = valorFormater.replace(/[,.]/g, function (match) {
        return match === ',' ? '.' : ','
    })

    return output
}