import { Alert, Grid } from "@mui/material";
import { Field, Form, Formik } from "formik"

import FormikControl from "../componentes/FormikControl"
import { mensajesSchema } from "../schemas/schemas";

import { Stack } from "@mui/joy";
import ButtonForm from "../../../components/Button/ButtonForm";
import PrimarySearchAppBar from "../../messages/navbar";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

const MessagesForm = ({ initialValues, onSubmit }) => {

    const history = useNavigate()

    const firstNameRef = useRef(null)

    const stateOptions = [
        { key: 'Activa', value: 'T' },
        { key: 'Inactiva', value: 'F' }
    ]

    const comeback = () => {
        history('/')
    }

    useEffect(() => {
        if (firstNameRef.current) {
            firstNameRef.current.focus();
        }
    }, [firstNameRef])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={mensajesSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, values, formik }) => {
                return (
                    <div>
                        <PrimarySearchAppBar />
                        <Form>
                            <Field name="fldmensaje_uuid" type="hidden" />

                            <FormikControl
                                type="text"
                                innerRef={firstNameRef}
                                control="input"
                                label="Nombre"
                                name="fldmensaje_nombre"
                                placeholder="Ingrese un nombre"
                                autoComplete="off" />

                            <br />

                            <FormikControl
                                type="text"
                                control="input"
                                label="Codigo"
                                name="fldmensaje_codigo"
                                placeholder="Ingrese un codigo"
                                autoComplete="off" />

                            <br />

                            <FormikControl
                                control="select"
                                label="Estado"
                                name="fldmensaje_activo"
                                options={stateOptions}
                            />

                            <br />

                            <FormikControl
                                control="RTE"
                                label="Contenido"
                                name="fldmensaje_contenido"
                            />

                            <br />

                            <Grid item container spacing={1} justify="center" style={{ marginTop: "2px" }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    {(
                                        errors.fldmensaje_nombre
                                        || errors.fldmensaje_codigo
                                        || errors.fldmensaje_contenido
                                        || errors.fldmensaje_activo
                                    ) &&
                                        (
                                            touched.fldmensaje_nombre
                                            || touched.fldmensaje_codigo
                                            || touched.fldmensaje_contenido
                                            || touched.fldmensaje_activo
                                        ) && (
                                            <Alert severity="error">{
                                                errors.fldmensaje_nombre
                                                || errors.fldmensaje_codigo
                                                || errors.fldmensaje_contenido
                                                || errors.fldmensaje_activo
                                            }</Alert>
                                        )}
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                        sx={{ width: '100%' }}>
                                        <ButtonForm
                                            type="submit"
                                            label="Grabar"
                                            disabled={!Object.keys(values).some(key => values[key] !== initialValues[key])}

                                        />
                                        <ButtonForm type="button" label="Cancelar" onClick={comeback} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Form>
                    </div>
                )
            }}
        </Formik >
    )
}

export default MessagesForm