import Cookies from 'js-cookie'

const getMensaje = async (id) => {
    if (id !== undefined || id !== null) {
        const endpoint = "http://10.0.0.158:8000/messages/" + id
        const instance = localStorage.getItem("instance")
        const me = localStorage.getItem("api") + "me"

        try {
            const config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
                    "Permission": me
                },
            }

            const response = await fetch(endpoint, config)
            const json = await response.json()
            return json
        } catch (error) {
            throw Error('Se ha producido un error durante el fetch')
        }
    } else {
        throw Error('El id es requerido')
    }
}

export default getMensaje