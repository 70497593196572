const header = [
    {
        name: 'Ip'
    },
    {
        name: 'Id_regla',
        display: false
    },
    {
        name: 'Nombre'
    },
    {
        name: 'Fecha'
    },
    {
        name: 'Usuario'
    },
    {
        name: 'Id'
    }
]

export default header