import Cookies from 'js-cookie'
import moment from 'moment'
import 'moment-timezone';

function conviertefecha(date) {
    const fecha = moment.tz(new Date(date), "America/Bogota")
    return fecha
}

const statesFilter = async ({ start_date, end_date, states, fields }) => {

    const route = "process"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const url = endpoint + "states"
    const instance = localStorage.getItem("instance")
    const id = localStorage.getItem("id")

    const format = "YYYY-MM-DD"

    let hora_inicial = null
    let hora_final = null

    if (start_date) {
        hora_inicial = conviertefecha(start_date)
    }

    if (end_date) {
        hora_final = conviertefecha(end_date)
    }

    let header = {
        id: id,
        filter: {
            "start_date": hora_inicial !== null ? moment(hora_inicial).format(format) : "",
            "end_date": hora_final !== null ? moment(hora_final).format(format) : "",
            "states": states,
            "fields": fields
        }
    }

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
            },
            body: JSON.stringify(header)
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json

    } catch (error) {
        console.log(error)
    }
}

export default statesFilter