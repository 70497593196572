import Cookies from 'js-cookie'

const eliminarMensaje = async (id) => {
    const url = "http://10.0.0.158:8000/messages/" + id
    const instance = localStorage.getItem("instance")
    const me = localStorage.getItem("api") + "me"

    try {
        const config = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
                "Permission": me
            },
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json
    } catch (error) {
        console.log(error)
    }
}

export default eliminarMensaje