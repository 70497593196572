import { createContext, useContext, useMemo } from "react";
import IpsValidas from "./ipsvalidas";

const AppContext = createContext({
    status: {},
    columnsState: [],
})

const RulesContext = () => {

    let status = null
    let columnsState = null

    return (
        <AppContext.Provider value={useMemo(() => ({ status, columnsState }), [columnsState, status])}>
            <IpsValidas />
        </AppContext.Provider>
    )
}

export default RulesContext

export function useAppContext() {
    return useContext(AppContext)
}