const EsLabels = {
    body: {
        noMatch: "No existen registros",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
        next: "Siguiente página",
        previous: "Página Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
    },
    toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver Columnas",
        filterTable: "Filtrar Tabla",
    },
    filter: {
        all: "Todo",
        title: "FILTRAR",
        reset: "RESTABLECER",
    },
    viewColumns: {
        title: "Columnas",
        titleAria: "Mostrar/Ocultar Columna",
    },
    selectedRows: {
        text: "Fila seleccionada",
        delete: "Eliminar",
        deleteAria: "Eliminar Fila Seleccionada",
    },
}

export default EsLabels