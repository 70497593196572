import { useNavigate, useParams, } from "react-router-dom";
import useMensajeForm from "../../../hooks/Mensajes/useMensajeForm";

import Loading from "../../../components/Loading/Loading";
import MessagesForm from "./messagesForm";
import { useEffect, useState } from "react";

const MessagesUpdate = () => {

    const { id } = useParams()
    const history = useNavigate()
    const { updateMensaje, getMessageById } = useMensajeForm()

    const [initialValues, setInitialValues] = useState(null)

    const onSubmit = async (fields, { setStatus, setSubmitting }) => {

        fields.fldmensaje_nombre = fields.fldmensaje_nombre.trim()
        fields.fldmensaje_codigo = fields.fldmensaje_codigo.trim()
        fields.fldmensaje_contenido = fields.fldmensaje_contenido.trim()

        setInitialValues({
            fldmensaje_uuid: fields.fldmensaje_uuid,
            fldmensaje_nombre: fields.fldmensaje_nombre,
            fldmensaje_codigo: fields.fldmensaje_codigo,
            fldmensaje_contenido: fields.fldmensaje_contenido,
            fldmensaje_activo: fields.fldmensaje_activo
        })

        updateMensaje(fields)
    }

    useEffect(() => {
        const fields = [
            'fldmensaje_uuid',
            'fldmensaje_nombre',
            'fldmensaje_codigo',
            'fldmensaje_contenido',
            'fldmensaje_activo'
        ]

        if (id !== undefined && id !== null) {
            initializeUpdate(fields);
        } else {
            history("/")
        }

        function initializeUpdate(fields) {
            const data = getMessageById(id);
            const values = {};

            data.then((dato) => {
                if (dato === null) {
                    history("/")
                }

                else if (dato !== undefined && dato !== null) {
                    fields.forEach(field => {
                        if (dato[field] !== undefined || dato[field] !== null) {
                            values[field] = dato[field]
                        }
                    });
                }
                setInitialValues(values);
            })
        }
    }, [])

    if (initialValues === null) {
        return <Loading />
    }

    return (
        <MessagesForm initialValues={initialValues} onSubmit={onSubmit} />
    )
}

export default MessagesUpdate