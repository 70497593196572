import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function AlertPage() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="almera information managment"
        height="140"
        image="https://almeraim.com/assets/resources/logotop.png"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Alerta
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Al parecer no contamos con la información necesaria para ejecutar las operaciones en este sitio, por favor comunícate con un asesor.
        </Typography>
      </CardContent>
    </Card>
  );
}
