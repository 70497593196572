import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import wordCloud from "highcharts/modules/wordcloud.js"
import ChartContainer from './ChartContainer';

import defaultConfig from './Config/config'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

wordCloud(Highcharts)

const getOptions = (data, title) => {
    const options = {
        chart: {
            height: "100%",
        },
        title: {
            text: title
        },
        tooltip: {
            valueSuffix: '',
            pointFormat: '<span>Concurrencia</span>: <b>{point.weight}</b>'

        },
        series: [
            {
                type: "wordcloud",
                turboThreshold: 3000,
                data: data
            }
        ]
    }

    return Object.assign({}, options, defaultConfig)
}

const TagCloud = ({ data, title = "grafico" }) => {

    const filteredData = data.sort(function (a, b) {
        return b.weight - a.weight;
    }).slice(0, 300)

    return (
        <ChartContainer >
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(filteredData, title)}
            />
        </ChartContainer>
    )
}

export default TagCloud