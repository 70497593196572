import { Stack } from "@mui/joy"

const ChartContainer = (props) => {
    return (
        <Stack direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: "2em", marginBottom: "2em" }}
        >
            {props.children}
        </Stack>
    )
}

export default ChartContainer