import { useEffect, useState } from "react"
import { Menu, Sidebar, MenuItem, useProSidebar } from "react-pro-sidebar"

import { useSidebarContext } from "./sidebarContext"

import { useLocation, useNavigate } from "react-router-dom"
import { tokens } from "./theme"
import { useTheme, Box, Typography, IconButton } from "@mui/material"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import { ArrowBack, AutoGraph, FilterList } from "@mui/icons-material"

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MenuItem
            active={selected === title}
            style={
                selected === title
                    ? { color: colors.redAccent[500] }
                    : { color: colors.grey[100] }}
            onClick={() => {
                if (title !== 'Filtrar') {
                    setSelected(title)
                }

                to()
            }}
            icon={icon}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    )
}

const MyProSidebar = ({ filter }) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const route = queryParams.get('route')

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Estado");
    const { sidebarRTL, sidebarImage } = useSidebarContext();
    const { collapseSidebar, collapsed } = useProSidebar();
    const navigate = useNavigate()

    const handleClick = (url) => {
        window.location.replace(url);
    };

    const redirigir = (url) => {
        return navigate(url)
    }

    useEffect(() => {
        if (!collapsed) {
            collapseSidebar()
        }
    }, [])

    return (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
                zIndex: 10000,
                "& .sidebar": {
                    border: "none",
                },
                "& .menu-icon": {
                    backgroundColor: "transparent !important",
                },
                "& .menu-item": {
                    backgroundColor: "transparent !important",
                },
                "& .menu-anchor": {
                    color: "inherit !important",
                    backgroundColor: "transparent !important",
                },
                "& .menu-item:hover": {
                    color: `${colors.blueAccent[500]} !important`,
                    backgroundColor: "transparent !important",
                },
                "& .menu-item.active": {
                    color: `${colors.greenAccent[500]} !important`,
                    backgroundColor: "transparent !important",
                },
            }}
        >
            <Sidebar
                breakPoint="md"
                rtl={sidebarRTL}
                backgroundColor={colors.primary[400]}
                image={sidebarImage}
            >
                <Menu iconshape="square">
                    <MenuItem
                        icon={
                            collapsed ? (
                                <MenuOutlinedIcon onClick={() => collapseSidebar()} /> //barra colapsada FS
                            ) : <div />
                        }
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                </Typography>
                                <IconButton
                                    onClick={
                                        () => collapseSidebar()
                                    }
                                >
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={collapsed ? undefined : "10%"}>
                        <Item
                            title="Regresar"
                            to={() => handleClick(localStorage.getItem("back"))}
                            icon={<ArrowBack titleAccess="Regresar" />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Filtrar"
                            to={() => filter()}
                            icon={<FilterList titleAccess="Filtrar" />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Estado"
                            to={() => redirigir("/?route=" + route)}
                            icon={<HomeOutlinedIcon titleAccess="Estado" />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Atributos"
                            to={() => redirigir("/attributes?route=" + route)}
                            icon={<AutoGraph titleAccess="Atributos" />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    )
}

export default MyProSidebar;