import { Delete, Edit } from "@mui/icons-material";
import { Stack } from "@mui/joy";
import { headerConf } from "../../components/Table/TableMethod"

export function botones(editar, eliminar, colDeleteId) {
    return (value, tableMeta, updateValue) => {
        return (
            <Stack direction="row" spacing={1}>
                <Edit onClick={() => editar({ ip: tableMeta.rowData[0], id: tableMeta.rowData[1], name: tableMeta.rowData[2] })} />
                <Delete onClick={() => eliminar(tableMeta.rowData[colDeleteId])} />
            </Stack>
        )
    }
}

export function actionsColumn(editar, eliminar, colDeleteId) {
    return {
        name: "",
        label: "",
        options: {
            viewColumns: false,
            filter: false,
            sort: false,
            customBodyRender: botones(editar, eliminar, colDeleteId)
        }
    }
}

export function TableConfig(atributos, statusTable, editar, eliminar, colDeleteId = 1) {
    let encabezadoTabla = headerConf(atributos, statusTable)
    const boton = actionsColumn(editar, eliminar, colDeleteId)
    encabezadoTabla.push(boton)
    return encabezadoTabla
}