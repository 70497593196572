import React from 'react';
import { MultiSelect } from './ExtendedMultiSelect';

export default function AnimatedMulti({ field_id, options, name, selected, setSelected, required = [] }) {

    const items = []

    if (options !== null && options !== undefined) {
        const objectArray = Object.entries(options);

        objectArray.forEach(([key, valor]) => {
            items.push({ value: valor.value, label: valor.label })
        });
    }

    return (
        <>
            <h6 style={{ marginBottom: 2 }}>{name} {required.includes(name) ? <span style={{ color: "red", fontSize: "18px" }} >*</span> : <span />}</h6>
            <MultiSelect field_id={field_id} options={items} value={selected} onChange={setSelected} />
        </>
    );
}