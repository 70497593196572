import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';

const Redirect = (props) => {

    const [searchParams, setSearchParams] = useSearchParams()

    const dataAlmacenamiento = () => {
        for (const [key, value] of searchParams) {
            localStorage.setItem(key, value)
        }
    }

    const removeQueryParams = (parameters) => {

        for (const i of parameters) {
            if (i !== 'route') {
                const param = searchParams.get(i);

                if (param) {
                    searchParams.delete(i);
                    setSearchParams(searchParams);
                }
            }
        }
    }

    useEffect(() => {
        dataAlmacenamiento()

        let parameters = []

        for (const [key] of searchParams) {
            parameters.push(key)
        }

        if (parameters.length > 0) {
            removeQueryParams(parameters)
        }
    }, [])

    return (
        <div />
    )
}

export default Redirect