import * as yup from "yup"
import validate from "ip-validator"

const regexIp = /((^\s*(((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
const blankSpace = /^\s*\S[\s\S]*$/
const onlyNumbers = /^\d*$/

function ipsSeparate(value) {
  let inicial = value.replace(/\n/gi, ",")
  inicial = inicial.replace(/\s/gi, ",")
  inicial = inicial.replace(/;/gi, ",")
  const valores = inicial.split(",")
  return valores.filter(valor => valor.trim() !== '')
}

function hasDuplicates(arr) {
  return new Set(arr).size !== arr.length;
}

function verifyStateIps(value) {
  return value !== undefined && (value.includes(",") || value.includes("\n") || value.includes(";") || value.includes(" "))
}

const validarMascara = ({ ip, mascara }) => {

  let stringMask = onlyNumbers.test(mascara)
  mascara = parseInt(mascara)

  if (mascara !== null && mascara !== undefined && stringMask) {
    if (mascara !== undefined && ip !== undefined) {
      if (validate.ipv4(ip) === true && regexIp.test(ip)) {
        return mascara >= 1 && mascara <= 32
      } else if (validate.ipv6(ip) === true && regexIp.test(ip)) {
        return mascara >= 1 && mascara <= 128
      }
    }
  }

  return false
}

yup.addMethod(yup.string, 'ip', function (message) {
  return this
    .test({
      name: 'ip',
      exclusive: true,
      message: message || 'La ip es inválida',
      test: async function (value) {
        if (value !== undefined) {

          value = value.trim()

          if (value.includes("/")) {
            const valores = value.split("/")

            if (regexIp.test(valores[0]) && validarMascara({ ip: valores[0], mascara: valores[1] })) {
              return true
            }
          } else {
            return regexIp.test(value)
          }
        }
        return false
      }
    })
})

yup.addMethod(yup.string, 'caracteres', function (message) {
  return this
    .test({
      name: 'caracteres',
      exclusive: true,
      message: message || 'El nombre es invalido',
      test: async function (value) {
        let total = 0;

        if (value !== undefined) {

          value = value.trim()
          for (let i = 0; i < value.length; i++) {

            if (value.charAt(i) !== ' ') {
              total++
            }
          }
        }

        return total >= 2
      }
    })
})

function validateIp(ip, errores, valores) {
  if (ip.includes("/")) {
    const ip_actual = ip.split("/")

    if (regexIp.test(ip_actual[0]) === false || validarMascara({ ip: ip_actual[0], mascara: ip_actual[1] }) === false) {
      errores.push(valores[ip])
    }
  } else {
    if (regexIp.test(ip) === false) {
      errores.push(valores[ip])
    }
  }
}

yup.addMethod(yup.string, "maskRequired", function (message) {
  return this
    .test({
      name: 'ipsmasivas',
      exclusive: true,
      message: message || 'La máscara de red es requerida',
      test: async function (value) {
        return value.includes("/")
      }
    })
})

yup.addMethod(yup.string, 'ipsmasivas', function (message) {
  return this
    .test({
      name: 'ipsmasivas',
      exclusive: true,
      message: message || 'El conjunto de ips es inválido',
      test: async function (value) {
        if (verifyStateIps(value)) {
          const valores = ipsSeparate(value)

          const errores = []

          for (let ip of valores) {
            if (ip !== '' && ip !== undefined) {
              validateIp(ip, errores, valores)
            }
          }

          return errores.length > 0 ? false : true
        }
      }
    })
})

yup.addMethod(yup.string, 'ipsminimo', function (message) {
  return this
    .test({
      name: 'ipsminimo',
      exclusive: true,
      message: message || 'El conjunto debe tener al menos dos ips',
      test: async function (value) {
        if (value !== undefined && value.trim() !== '') {
          if (verifyStateIps(value)) {
            const valores = ipsSeparate(value)
            const ipvalidas = []

            for (let ip of valores) {
              const valor = ip.trim()

              if (valor !== '' && valor !== undefined) {
                ipvalidas.push(ip)
              }
            }

            return ipvalidas.length >= 2
          }
        }
        return false
      }
    })
})

yup.addMethod(yup.string, 'mascarasmasivas', function (message) {
  return this
    .test({
      name: 'ipsminimo',
      exclusive: true,
      message: message || 'Todas las direcciones ips deben tener máscara',
      test: async function (value) {
        if (value !== undefined) {
          if (verifyStateIps(value.trim())) {
            const valores = ipsSeparate(value.trim())

            for (let ip of valores) {
              if (!ip.includes("/")) {
                return false
              }
            }

            return true
          }
        }
        return false
      }
    })
})

yup.addMethod(yup.string, "ipsrepetidas", function (message) {
  return this
    .test({
      name: 'ipsrepetidas',
      exclusive: true,
      message: message || 'Algunas ips en tu conjunto se están repitiendo',
      test: async function (value) {
        if (verifyStateIps(value)) {
          let valores = ipsSeparate(value)
          valores = valores.filter(valor => valor.trim() !== '' && valor.trim() !== undefined)

          return hasDuplicates(valores) ? false : true
        }
      }
    })
})

export const ipsSchema = yup.object().shape({
  name: yup.string().trim()
    .required("El nombre es requerido")
    .min(2, "El nombre debe tener al menos dos caracteres")
    .matches(blankSpace, "El nombre es requerido")
    .caracteres("El nombre no es válido")
  ,
  ip: yup.string().trim()
    .required("La ip es requerida")
    .ip("La ip no es válida")
    .maskRequired()
})

export const ipsMasivasSchema = yup.object().shape({
  name: yup.string().trim()
    .required("El nombre es requerido")
    .min(2, "El nombre debe tener al menos dos caracteres")
    .matches(blankSpace, "El nombre es requerido")
    .caracteres("El nombre no es válido")
  ,
  ip: yup.string().trim()
    .required("Las ips son requeridas")
    .ipsminimo()
    .ipsmasivas()
    .ipsrepetidas()
    .mascarasmasivas()
})

export const mensajesSchema = yup.object().shape({
  fldmensaje_nombre: yup.string().trim()
    .required("El nombre es requerido")
    .min(2, "El nombre debe tener al menos dos caracteres")
    .matches(blankSpace, "El nombre es requerido"),
  fldmensaje_codigo: yup.string()
    .required("El código es requerido").trim()
    .min(2, "El codigo debe tener al menos dos caracteres")
    .matches(blankSpace, "El código es requerido")
  ,
  fldmensaje_contenido: yup.string().trim()
    .required("El contenido es requerido")
    .matches(blankSpace, "El contenido es requerido")
  ,
  fldmensaje_activo: yup.string()
    .required("El estado es requerido")
    .oneOf(['T', 'F'])
})