import { TableCell, TableFooter, TableRow } from '@mui/material';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { formatNumberSeparateMiles } from '../../utils/Number';
import { sortFloat, sortInteger, sortMonths } from '../../utils/Sort';
import BasicTable from '../Table/BasicTable'
import ChartContainer from './ChartContainer';
import defaultConfig from './Config/config';
import { decimalFormat, decimalFormatInteger, nameCustom } from './Format/format';

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getOptions = (data, amountData, categories, title, total = null) => {

    const options = {
        chart: {
            type: 'column',
            height: "60%",
            width: 900
        },
        title: {
            text: title
        },
        yAxis: {
            title: {
                text: null,
            },
        },
        xAxis: {
            categories: categories
        },
        series: [
            {
                name: "Valor",
                data: data,
                colorByPoint: true,
                showInLegend: true
            },
            {
                name: "Cantidad",
                type: 'spline',
                dashStyle: 'Dot',
                color: "black",
                data: amountData
            }
        ],
    }

    return Object.assign({}, options, defaultConfig)
}

const footer = ({ data, total }) => {
    const totalCantidad = data.reduce((
        act, [_, , cantidad]) => act + parseInt(cantidad), 0)

    const totalPorcentaje = data.reduce((
        act, [_, , , promedio]) => act + parseFloat(promedio.split(" ")[0]), 0)

    const output = formatNumberSeparateMiles(total)

    return (
        <TableFooter >
            <TableRow >
                <TableCell align='left'>Total</TableCell>
                <TableCell align='right'>{output}</TableCell>
                <TableCell align='right'>{formatNumberSeparateMiles(totalCantidad)}</TableCell>
                <TableCell align='right'>{decimalFormat(totalPorcentaje)} %</TableCell>
            </TableRow>
        </TableFooter>
    )
}

function customSort(data, colIndex, order) {
    if (colIndex === 0) {
        return sortMonths(data, colIndex, order)
    } else if (colIndex === 3) {
        return sortFloat(data, colIndex, order)
    } else {
        return sortInteger(data, colIndex, order)
    }
}

const columns = [
    {
        name: "Nombre",
        options: {
            setCellProps: value => {
                return {
                    style: {
                        fontWeight: 'bold',
                    },
                };
            },
            customBodyRender: nameCustom
        }
    },
    {
        name: "Valor",
        options: {
            customBodyRender: decimalFormat,
        }
    },
    {
        name: "Cantidad",
        options: {
            customBodyRender: decimalFormatInteger,
        }
    },
    "Porcentaje"
]

const BarSplineChart = ({ data, total = null, title = "grafico" }) => {

    const valores = []
    const encabezado = []
    const spline = []

    const dataTable = []

    data.forEach(element => {
        valores.push(parseFloat(element.y))
        encabezado.push(element.name)
        spline.push(parseInt(element.process))
        const percent = Number(element.percent.toFixed(2))
        const amount = isNaN(parseFloat(element.y)) ? 0 : parseFloat(element.y)
        dataTable.push([element.name, amount, element.process, percent + " %"])
    })

    return (
        <ChartContainer >
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(valores, spline, encabezado, title)}
            />

            <br />

            <BasicTable
                columns={columns}
                data={dataTable}
                Footer={footer}
                customSort={customSort}
                total={total}
            />

        </ChartContainer>
    )
}

export default BarSplineChart