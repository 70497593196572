export function removeOption(actionMeta, selected, pos) {
    if (actionMeta.removedValue.value === "<SELECT_ALL>") {
        clear(selected, pos)
    } else {
        const posicion = selected[pos]["items"].indexOf(actionMeta.removedValue)

        if (posicion !== -1) {
            selected[pos]["items"].splice(posicion, 1)
        }
    }
}

export function deselectOption(actionMeta, selected, pos) {
    if (actionMeta.option.value === "<SELECT_ALL>") {
        clear(selected, pos)
    } else {
        let deletePosition = null

        selected[pos]["items"].forEach((v, index) => {
            if (v.value === actionMeta.option.value) {
                deletePosition = index
            }
        })

        if (deletePosition !== -1 && deletePosition !== null) {
            selected[pos]["items"].splice(deletePosition, 1)
        }
    }
}

export function selectOption(actionMeta, selected, pos, value) {
    if (actionMeta.option.value === "<SELECT_ALL>") {
        clear(selected, pos)

        value.forEach(element => {
            selected[pos]["items"].push(element)
        })

    } else {
        selected[pos]["items"].push(actionMeta.option)
    }
}

export function clear(selected, pos) {
    selected[pos]["items"].splice(0, selected[pos]["items"].length)
}