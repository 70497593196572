import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import defaultConfig from './Config/config'
import ChartContainer from './ChartContainer'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getOptions = (data, title) => {
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: "60%",
            width: 900
        },
        title: {
            text: title
        },
        tooltip: {
            pointFormat: '{point.percentage:.2f} % <b>({point.y})</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.2f} %'
                }
            }
        },
        series: [
            {
                name: "SMM porcentajes",
                colorByPoint: true,
                showInLegend: true,
                data: data
            }
        ],
    }

    return Object.assign({}, options, defaultConfig)
}

const PieChart = ({ data, title = "grafico", ...props }) => {
    return (
        <ChartContainer>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(data, title)}
            />

            {props.children}
        </ChartContainer>
    )
}

export default PieChart