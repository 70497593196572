import AnimatedMulti from "../../components/Select/selectFilter"
import { Grid, Stack } from "@mui/joy"
import { useState } from "react"
import DatePickerFilter from "../../components/DatePicker/DatePickerFilter"

import ModalFilter from "../../components/Modal/modalFilter"
import { clear, deselectOption, removeOption, selectOption } from "../../components/Select/SelectFilterModules"

import { useAppContext } from "./layout"

const Filtro = ({ filtrar }) => {

    const [refresh, setRefresh] = useState(false)

    const store = useAppContext()

    const setSelected = ((field_id, value, actionMeta) => {
        const pos = getPostionSelected(field_id)

        switch (actionMeta.action) {
            case 'select-option':
                selectOption(actionMeta, store.selected, pos, value)
                break
            case 'deselect-option':
                deselectOption(actionMeta, store.selected, pos)
                break
            case 'remove-value':
                removeOption(actionMeta, store.selected, pos)
                break
            case 'clear':
                clear(store.selected, pos)
                break
            default:
                throw new Error()
        }

        setRefresh(!refresh)
    })

    const getPostionSelected = ((id) => {
        for (let i = 0; i < store.selected.length; i++) {
            if (store.selected[i]["field_id"] === id) {
                return i
            }
        }
        return null
    })

    return (
        <div>
            <ModalFilter
                open={store.open}
                handleClose={() => store.handleClose()}
                filtrar={() => filtrar(store.startDate, store.endDate)}
            >
                <Grid xs={6}>
                    <DatePickerFilter
                        getDatePicker={store.setStartDate}
                        label="Desde"
                        defaultValue={store.startDate}
                    />
                </Grid>

                <Grid xs={6}>
                    <DatePickerFilter
                        getDatePicker={store.setEndDate}
                        label="Hasta"
                        defaultValue={store.endDate} />
                </Grid>

                <Grid xs={12}>
                    <Stack direction="column" spacing={1}>
                        {

                            store.filtro.map((opt) => {
                                const pos = getPostionSelected(opt.id)

                                return (
                                    <AnimatedMulti
                                        key={opt.id}
                                        field_id={opt.id}
                                        name={opt.name}
                                        options={opt.items}
                                        selected={store.selected[pos]["items"]}
                                        required={["Estados"]}
                                        setSelected={setSelected}
                                    />
                                )
                            })
                        }
                    </Stack>
                </Grid>
            </ModalFilter>
        </div >
    )
}

export default Filtro