import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { BarChart, LocalFireDepartment } from '@mui/icons-material';
import PieChartIcon from '@mui/icons-material/PieChart';
import PieChart from './PieChart';
import BarChartBasic from './BarChartBasic';
import BasicTable from '../Table/BasicTable';
import { decimalFormatInteger, nameCustom } from './Format/format';
import { customSort } from './Sort/sort';
import { TableCell, TableFooter, TableRow } from '@mui/material';

const columns = [
    {
        name: "Nombre",
        options: {
            setCellProps: value => {
                return {
                    style: {
                        fontWeight: 'bold',
                    },
                };
            },
            customBodyRender: nameCustom
        }
    },
    {
        name: "Cantidad",
        options: {
            customBodyRender: decimalFormatInteger,
        }
    },
    "Porcentaje"]

export default function SMM({ data, title }) {

    const dataTable = []

    data.forEach(element => {
        const percent = Number(element.percent.toFixed(2))
        dataTable.push([element.name, element.y, percent + " %"])
    })

    const footer = ({ data }) => {

        const total = data.reduce((acc, [_, value]) => acc + value, 0);

        return (
            <TableFooter >
                <TableRow >
                    <TableCell>Total</TableCell>
                    <TableCell align='right'>{total}</TableCell>
                    <TableCell align='right'>100%</TableCell>
                </TableRow>
            </TableFooter>
        )
    }

    return (
        <div style={{ paddingTop: "1em" }}>
            <Tabs
                aria-label="chart tabs"
                defaultValue={0}
                sx={{ mb: 2, borderRadius: 'lg' }}>
                <TabList>
                    <Tab >
                        <ListItemDecorator>
                            <PieChartIcon /> Torta
                        </ListItemDecorator>
                    </Tab>
                    <Tab >
                        <ListItemDecorator>
                            <BarChart /> Barras
                        </ListItemDecorator>

                    </Tab>
                    <Tab>
                        <ListItemDecorator>
                            <LocalFireDepartment />  Mapa de calor
                        </ListItemDecorator>
                    </Tab>
                </TabList>

                <TabPanel>
                    <PieChart data={data} title={title}>
                        <BasicTable
                            columns={columns}
                            data={dataTable}
                            Footer={footer}
                            customSort={customSort}
                        />
                    </PieChart>
                </TabPanel>
                <TabPanel>
                    <BarChartBasic data={data} title={title}>
                        <BasicTable
                            columns={columns}
                            data={dataTable}
                            Footer={footer}
                            customSort={customSort}
                        />
                    </BarChartBasic>
                </TabPanel>
                <TabPanel>
                    opción aún no disponible
                </TabPanel>
            </Tabs>
        </div>
    )
}