import React from "react"
import { Field } from "formik"
import './estilos.css'

function Textarea(props) {
    const { label, name, ...rest } = props

    return (
        <div className="form-control">
            <label htmlFor={name}>{label}</label>
            <Field className="my-input" as="textarea" id={name} name={name} {...rest} rows={10} />
            <br />
        </div>
    )
}

export default Textarea