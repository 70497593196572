import styled from 'styled-components'

const Button = styled.button`
border-radius: 0.25em;
border: none;
background: ${props => props.bgColor || 'red'};
color: #fff;
font-weight: 500;
padding: 0.7em 1.4em;
cursor: pointer;
height: 2.5em;
align-items: center;
transition: background 0.3s ease;
    &:hover {
        background: ${props => props.bgColor || 'red'};
        background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
    }`;

const ButtonForm = (props) => {
    const { type, label, onClick, ...rest } = props

    return (
        <Button
            bgColor={type === "submit" ? "#008F39" : "#dd3333"}
            type={type}
            onClick={onClick}
            {...rest}
        >{label}</Button>
    )
}

export default ButtonForm