import Loading from '../../components/Loading/Loading'
import { Stack } from "@mui/joy"
import React, { useState } from "react"
import statesFilter from "../../services/Tramites/states"
import ChartBar from "../../components/Chart/Bar"

import { validaDate } from "../../modules/date"
import { getFieldsSelected, setStateDay, setStateTotal } from "../../modules/chartData"

import alertify from "alertifyjs"
import { useAppContext } from "./layout"
import Filtro from "./filter"
import { getFilterState } from './utils/state'

const decimalFormat = value => <span>{value !== 0 ? value.toFixed(2) : 0}</span>

const Tramites = () => {

    const [dataChart, setDataChart] = useState(null)
    const [dataPieChart, setDataPieChart] = useState(null)
    const [entorno, setEntorno] = useState(false)
    const [loading, setLoading] = useState(false)

    const store = useAppContext()

    const filtrar = (() => {
        let statusFilter = false
        const statusDateValidate = validaDate(store.startDate, store.endDate)
        statusFilter = statusDateValidate
        const states = getFilterState(store)
        
        if (statusDateValidate) {
            if (states.length <= 0) {
                alertify.error("Se debe seleccionar al menos un estado")
                statusFilter = false
            }
        } else {
            setDataPieChart([])
            setDataChart([])
        }

        if (statusFilter === true) {
            setLoading(true)
            const fields = getFieldsSelected(store.selected)

            if (store.open === true) { store.handleClose() }
            statesFilter({
                start_date: store.startDate,
                end_date: store.endDate,
                states: states,
                fields: fields
            }).then((datos) => {
                setStateDay(datos, setDataChart)
                setStateTotal(datos, setDataPieChart)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setLoading(false)
            })
        }
    })

    const columnsPie = [
        "Estado",
        {
            name: "Porcentaje",
            options: {
                customBodyRender: decimalFormat
            }
        },
        "Total",
        {
            name: "orden", options: {
                display: false,
                viewColumns: false,
            }
        }
    ]

    if (store.filtro && store.selected && entorno === false) {
        filtrar()
        setEntorno(true)
    }

    if (!store.filtro || !store.selected) {
        return (<Loading />)
    }

    return (
        <React.Fragment>
            <Filtro filtrar={filtrar} />
            {
                loading ? <Loading /> :
                    <Stack direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}>
                        {
                            dataChart === null ? <></> :
                                <ChartBar
                                    data={dataChart}
                                    title="Tiempo promedio en cada estado (dias)"
                                    columnas={["Estado", "Dias"]}
                                />
                        }

                        <br />

                        {
                            dataPieChart === null ? <></> :
                                <ChartBar
                                    type="pie"
                                    data={dataPieChart}
                                    title="Procesos por estado"
                                    columnas={columnsPie}
                                />
                        }
                    </Stack>
            }
        </React.Fragment>
    )
}

export default Tramites