import React from 'react';
import { Grid, Stack } from '@mui/joy'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const ModalFilter = ({ children, open, handleClose, filtrar }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            animationDuration={0}
        >
            <Grid container spacing={2}>
                {children}
            </Grid>

            <Stack direction="row" spacing={2} style={{ marginTop: "2em" }}>
                <Button style={{ backgroundColor: "#008f39", color: "#fff" }} onClick={filtrar}>Filtrar</Button>
                <Button style={{ backgroundColor: "#FF0000", color: "#fff" }} onClick={handleClose}>Cancelar</Button>
            </Stack>
        </Modal>
    )
}

export default ModalFilter