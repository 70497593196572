import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ChartContainer from './ChartContainer'
import defaultConfig from './Config/config'

require("highcharts/modules/accessibility")(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

const getOptions = (data, categories, title) => {

    const options = {
        chart: {
            type: 'column',
            height: "60%",
            width: 900
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                }
            }
        },
        title: {
            text: title
        },
        yAxis: {
            title: {
                text: null,
            },
        },
        xAxis: {
            categories: categories
        },
        series: [
            {
                name: "Cantidad",
                data: data,
                colorByPoint: true,
                showInLegend: true
            }
        ],
    }

    return Object.assign({}, options, defaultConfig)
}

const BarChartBasic = ({ data, title = "grafico", ...props }) => {

    const valores = []
    const encabezado = []

    data.forEach(element => {
        valores.push(parseFloat(element.y))
        encabezado.push(element.name)
    })

    return (
        <ChartContainer >
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions(valores, encabezado, title)}
            />
            <br />
            {props.children}
        </ChartContainer>
    )
}

export default BarChartBasic