import { Container } from "@mui/joy"
import alertify from "alertifyjs"
import React, { useState } from "react"
import ChartControl from "../../components/Chart/ControlChart"
import Loading from "../../components/Loading/Loading"
import SelectValue from "../../components/Select/Select"
import useGetAttributes from "../../hooks/Tramites/attributes"
import { getFieldsSelected } from "../../modules/chartData"
import { validaDate } from "../../modules/date"
import Filtro from "./filter"
import { useAppContext } from "./layout"
import { attributes, getFilterState, years } from "./utils/state"

const Attributes = () => {

    const store = useAppContext()
    const { getAttributes } = useGetAttributes()

    const [attribute, setAttributes] = useState('')
    const [year, setYear] = useState('')
    const [data, setData] = useState({ type: null, data: null, total_values: null })
    const [loading, setLoading] = useState(false)

    const processData = (id_attribute, type, filter) => {
        setLoading(true)

        getAttributes(id_attribute, type, filter).then(result => {
            setData({
                ...data,
                type: result ? type : 'no-data',
                data: result ? result.data : [],
                total_values: result?.total_values ? result.total_values : 0
            })
        }).finally(() => setLoading(false))
    }

    const handleChangeYear = (event) => {
        setYear(event.target.value)

        const states = getFilterState(store)
        const id_attribute = attribute.split("*")
        const fields = getFieldsSelected(store.selected)

        const filter = {
            states: states,
            year: event.target.value,
            fields: fields
        }

        processData(id_attribute[0], id_attribute[1], filter)
    }

    const handleChange = (event) => {
        setYear('')
        setAttributes(event.target.value)

        const states = getFilterState(store)
        const id_attribute = event.target.value.split("*")
        const fields = getFieldsSelected(store.selected)

        const filter = {
            states: states,
            fields: fields,
            end_date: store.endDate,
            start_date: store.startDate
        }

        processData(id_attribute[0], id_attribute[1], filter)
    }

    const filtrar = ((fechaInicial, fechaFinal) => {
        let statusFilter = false

        const statusDateValidate = validaDate(fechaInicial, fechaFinal)
        statusFilter = statusDateValidate
        const states = getFilterState(store)

        if (statusDateValidate) {
            if (states <= 0) {
                alertify.error("Se debe seleccionar al menos un estado")
                statusFilter = false
            }
        }

        if (statusFilter === true) {
            if (store.open === true) { store.handleClose() }

            const id_attribute = attribute.split("*")
            const fields = getFieldsSelected(store.selected)

            const filter = {
                states: states,
                fields: fields,
                end_date: fechaFinal,
                start_date: fechaInicial
            }

            processData(id_attribute[0], id_attribute[1], filter)
        }
    })

    const getTitle = () => {
        const search = attributes(store).find((option) => option.value === attribute)
        const title = search ? search.name : 'Grafica'
        return title
    }

    if (loading) {
        return <Loading />
    }

    if (!store.filtro || !store.selected) {
        return (<Loading />)
    }

    return (
        <React.Fragment>
            <Filtro filtrar={filtrar} />
            <Container>
                <SelectValue
                    label='Atributos'
                    options={attributes(store)}
                    value={attribute}
                    onChange={handleChange}
                />

                {
                    attribute.split("*")[1] === 'float' ?
                        <SelectValue
                            label='Año'
                            options={years(store)}
                            value={year}
                            onChange={handleChangeYear}
                        /> : <div />
                }
            </Container>

            <br />

            <ChartControl
                control={data.type}
                title={getTitle()}
                data={data.data ? data.data : []}
                total={data.total_values}
            />
        </React.Fragment>
    )
}

export default Attributes