export const getFilterState = (store) => {

    const states = []
    const filtro = store.selected

    filtro.forEach(field => {
        if (field['field_id'] === 'states') {

            const items = field['items']

            items.forEach(item => {
                states.push(item.value)
            })
        }
    })

    return states
}

export const attributes = (store) => {
    if (store.attributes) {
        return store.attributes.map((element) => ({
            key: element.id,
            value: `${element.id}*${element.type}`,
            name: `${element.name}`
        }))
    } else {
        return []
    }
}

export const years = (store) => {
    return store.years.map((element) => ({
        name: element
    }))
}