import React, { useState } from 'react';
import 'dayjs/locale/es'

import { TextField } from '@mui/material';
import { LocalizationProvider, esES, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'

const DatePickerFilter = ({ getDatePicker, label = "Selecciona una fecha", defaultValue = null }) => {
    const [value, setValue] = useState(defaultValue)
    const [open, setOpen] = useState(false)

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es"
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                maxDate={moment(new Date())}

                inputFormat="YYYY-MM-DD"
                label={label}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue)
                    getDatePicker(newValue)
                }}
                renderInput={(params) => <TextField {...params} onClick={(e) => setOpen(true)} sx={{ gridColumn: 12 }}
                />}
            />
        </LocalizationProvider>
    )
};

export default DatePickerFilter