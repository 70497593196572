import getAttributesFilter from "../../services/Tramites/attributes"
import moment from 'moment'
import 'moment-timezone';

function conviertefecha(date) {
    const fecha = moment.tz(new Date(date), "America/Bogota")
    return fecha
}

function useGetAttributes() {

    const format = "YYYY-MM-DD"

    const getAttributes = async (idattribute, type, filtro) => {
        let items = []

        if (filtro.end_date) {
            filtro.end_date = conviertefecha(filtro.end_date).format(format)
        }

        if (filtro.start_date) {
            filtro.start_date = conviertefecha(filtro.start_date).format(format)
        }

        try {

            const response = await getAttributesFilter(idattribute, filtro)

            if (type === 'string' || type === 'text') {

                let info
                ({ info } = stringTextManage(response, items))

                if (info.length <= 0) {
                    return null
                }

                return { data: info }

            } else if (type === 'float') {

                let { info, total_process, total_values } = floatManage(response)
                return {
                    data: info ? info : [],
                    total_process: total_process,
                    total_values: total_values
                }
            } else if (type === 'smu' || type === 'smm') {
                const data = smuSmmManage(response)

                return { data: data }

            } else {
                return null
            }
        } catch (error) {
            return null
        }
    }

    return { getAttributes }
}

export default useGetAttributes

function smuSmmManage(response) {
    let data = []

    if (response) {


        data = response.data.items ? response.data.items : null

        data.forEach(element => {
            element['name'] = element['nombre']
            element['y'] = element['total']
            delete element['nombre']
            delete element['total']
        })


    }
    return data
}

function floatManage(response) {
    let data = []
    let total_process = null
    let total_values = null
    const info = []

    if (response) {
        data = response.data ? response.data : []
        total_process = response.data.total_process ? response.data.total_process : null
        total_values = response.data.total_values ? response.data.total_values : null

        data.data.forEach(valor => {
            info.push({
                "name": valor.month,
                "y": valor.value,
                "process": valor.process,
                "percent": valor.percent
            })
        })
    }
    return { info, total_process, total_values }
}

function stringTextManage(response, items) {
    const info = []
    if (response) {
        if (response.data) {
            items = response.data

            if (items) {
                items.forEach(valor => {
                    info.push({
                        "name": valor[0],
                        "weight": valor[1]
                    })
                })
            }
        }
    }

    return { info }
}
