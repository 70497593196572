import React, { createContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Main from './pages/main'
import NotFound from './components/404/404'
import Layout from './pages/Tramites/layout'

import Cookies from 'js-cookie'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import MessageContext from './pages/messages/MessageContext';
import RulesContext from './pages/reglas/RulesContext';
import MessagesCreate from './pages/formularios/messages/messagesCreate';
import MessagesUpdate from './pages/formularios/messages/messagesUpdate';

function confirmQueryParameters(parametros) {
  for (const parametro of parametros) {
    if (!localStorage.getItem(parametro)) {
      return false
    }
  }

  return true
}

function confirmCookiesParameters() {
  const instance = localStorage.getItem("instance")
  const token = "tkreact_" + instance

  return Cookies.get(token)
}

function localStorageQuery(parametros, params) {
  for (const parametro of parametros) {
    if (params.get(parametro)) {

      if (parametro === 'api') {
        localStorage.setItem("api_" + params.get('route'), params.get(parametro))
      } else {
        localStorage.setItem(parametro, params.get(parametro))
      }
    }
  }
}

const parametros = ["api", "application", "endpoint", "host", "instance", "ip", "route", "id", "back"]
const parametrosSecurityGroup = ["api", "application", "endpoint", "host", "instance", "ip", "route"]
const parametrosProcess = ["id", "endpoint", "host", "api"]
const parametersMessage = ["endpoint", "api", "route"]

export const UserContext = createContext()
const params = new URLSearchParams(window.location.search)

localStorageQuery(parametros, params)

let link = params.get('route') || localStorage.getItem('route')

const Routing = () => {
  if (link === "instancia") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Main />} >
          </Route>
        </Routes>
      </BrowserRouter>
    )
  } else if (link === "adm_securitygroup" && confirmQueryParameters(parametrosSecurityGroup) && confirmCookiesParameters()) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<ErrorBoundary><RulesContext /></ErrorBoundary>}>
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
  else if (link === "process" && confirmQueryParameters(parametrosProcess) && confirmCookiesParameters()) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<ErrorBoundary><Layout /></ErrorBoundary>} />
        </Routes>
      </BrowserRouter>
    )
  } else if (link === "message" && confirmCookiesParameters() && confirmQueryParameters(parametersMessage)) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MessageContext />} />
          <Route path="/create" element={<MessagesCreate />} />
          <Route path="/update/:id" element={<MessagesUpdate />} />
        </Routes>
      </BrowserRouter>
    )
  }
  else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

function App() {
  return (
    <Routing />
  )
}

export default App