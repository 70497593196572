import Cookies from 'js-cookie'

const adicionarMensaje = async (datos) => {
    const url = "http://10.0.0.158:8000/messages"
    const instance = localStorage.getItem("instance")
    const me = localStorage.getItem("api") + "me"

    try {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get('tkreact_' + instance),
                "Permission": me
            },
            body: JSON.stringify(datos)
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json

    } catch (error) {
        console.log(error)
    }
}

export default adicionarMensaje