const editarRule = async (datos) => {

    const route = "adm_securitygroup"
    const endpoint = localStorage.getItem("api_" + route) || localStorage.getItem("api")
    const url = endpoint + "rule/" + datos["id"]
    const app = localStorage.getItem("application")

    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwOi8vMTAuMC4wLjkvc2dpLyIsImF1ZCI6InNnaSIsImp0aSI6InNnaWRlbW8iLCJpYXQiOjE2Nzc3MDY2MTkuMjg1ODY3OSwibmJmIjoxNjc3NzA2NjE5LjI4NTg2NzksImV4cCI6MTY4MDI5ODYxOS4yODU4Njc5LCJhdWlkIjoiNjA0In0.XHNFmMhC_HOJK4F8jwEa7XfPl22iFHOR78ki5QrqupLHgQFcnVG_m7ZQCiwbPKRvgN-dZGOOhq53oKFj1fA-dQ"

    try {
        const config = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
                "application": app,
            },
            body: JSON.stringify(datos)
        }

        const response = await fetch(url, config)
        const json = await response.json()
        return json

    } catch (error) {
        editarRule(datos)
        console.log(error)
    }
}

export default editarRule