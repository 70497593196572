import { useNavigate, } from "react-router-dom";
import useMensajeForm from "../../../hooks/Mensajes/useMensajeForm";

import Loading from "../../../components/Loading/Loading";
import MessagesForm from "./messagesForm";

const MessagesCreate = () => {

    const history = useNavigate()
    const { createMensaje } = useMensajeForm()

    const initialValues = {
        fldmensaje_nombre: '',
        fldmensaje_codigo: '',
        fldmensaje_contenido: '',
        fldmensaje_activo: 'T'
    }

    const onSubmit = async (fields, { setStatus, setSubmitting }) => {

        fields.fldmensaje_nombre = fields.fldmensaje_nombre.trim()
        fields.fldmensaje_codigo = fields.fldmensaje_codigo.trim()
        fields.fldmensaje_contenido = fields.fldmensaje_contenido.trim()

        let messageid = null

        await createMensaje(fields).then(result => {
            messageid = result
        }).catch((err => {
            messageid = null
        }))

        if (messageid !== null && messageid !== undefined) {
            history('/update/' + messageid)
        }
    }

    if (initialValues === null) {
        return <Loading />
    }

    return (
        <MessagesForm initialValues={initialValues} onSubmit={onSubmit} />
    )
}

export default MessagesCreate