import getIpsValidas from "../../services/Reglas/ipsvalidar"

const useGetRules = () => {
    const getRules = async () => {
        try {
            const response = await getIpsValidas()

            if (response !== null) {
                if (response.payload !== undefined && response.statusCode === 200) {
                    const datosTabla = [];
                    let datos = [];

                    datos = response.payload;

                    datos.forEach(element => {
                        const dato = [element.ip, element.rule_id, element.rule_name, element.timestamp, element.username, element.user_id]
                        datosTabla.push(dato)
                    })

                    return datosTabla

                } else {
                    return []
                }
            } else {
                return []
            }
        } catch (error) {
            return null
        }
    }

    return { getRules }
}

export default useGetRules