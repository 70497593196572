import { useLottie } from "lottie-react";
import animationData from './notfound.json'

const options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    style: {
        height: "60%",
        width: "60%",
    },
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
}

const NotFound = () => {

    const { View } = useLottie(options)

    return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>{View}</div>
}

export default NotFound