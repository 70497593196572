import { useEffect, useState } from "react";
import getOptionsFilter from "../../services/Tramites/filterFields";

function FilterValues() {

    const [data, setData] = useState(null)
    const [matriz, setMatriz] = useState(null)
    const [attributes, setAttributes] = useState(null)
    const [years, setYears] = useState(null)

    const estado = (states) => {
        const activos = []
        const opciones = []

        for (const state of states) {
            const attributo = { value: state.id, label: state.name }
            opciones.push(attributo)

            if (state.active === "T" && state.final === "F") {
                activos.push(attributo)
            }
        }

        const parametro = { id: "states", name: "Estados", items: opciones }
        const attr = { field_id: "states", items: activos }

        return { parametro, attr }
    }

    useEffect(() => {
        getOptionsFilter().then(result => {
            const filter = []
            const atributos = []

            if (result.data.states !== undefined) {
                const { parametro, attr } = estado(result.data.states)

                filter.push(parametro)
                atributos.push(attr)
            }

            if (result.data.fields !== undefined) {
                for (const filtro of result.data.fields) {
                    const id = filtro.id
                    const name = filtro.name
                    const opciones = []

                    for (const item of filtro.items) {
                        const value = { value: item.id, label: item.name }
                        opciones.push(value)
                    }

                    const parametro = { id: id, name: name, items: opciones }
                    const attr = { field_id: id, items: [] }
                    filter.push(parametro)
                    atributos.push(attr)

                }

                setData(JSON.stringify(filter))
                setMatriz(atributos)

            } else {
                setData([]);
            }

            if (result.data.attributes !== undefined) {
                const attributes = result.data.attributes
                setAttributes(attributes)
            }

            if (result.data.years) {
                const years = result.data.years
                setYears(years)
            }

        }).catch(error => {
            console.log(error);
        })
    }, [])

    return {
        filtro: JSON.parse(data),
        selected: matriz,
        attributes: attributes,
        years: years
    }
}

export default FilterValues